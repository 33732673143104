
import {Component, Vue} from 'vue-property-decorator'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'
import {joinPromoData, Subscription} from '@/util/promo'
import KionCard from '@/components/Shared/Cards/Card.vue'
import KionSmallCard from '@/components/Shared/Cards/SmallCard.vue'
import {mainService} from '@/util/mainService'
import KionModal from '@/components/Shared/modal/Modal.vue'
import {httpService} from '@/util/HttpAdapter'
import KionPaymentInit from '@/views/main/components/modals/PaymentInit.vue'
import KionPaymentConfirm from '@/views/main/components/modals/PaymentConfirm.vue'
import KionButton from '@/components/Shared/Button.vue'
import KionInput from '@/components/Shared/Input.vue'
import {gtm} from "@/util/GTMService";

export enum PaymentStep {
  Init,
  Phone,
  Card,
  Error,
  Success
}


@Component({
  components: {
    Template,
    KionTemplate,
    KionCard,
    KionSmallCard,
    KionModal,
    KionPaymentInit,
    KionPaymentConfirm,
    KionButton,
    KionInput
  }
})
export default class KionSubscriptions extends Vue {
  loading = false
  selectedSubscription: Subscription | null = null
  bigPictures: Subscription[] = []
  smallPictures: Subscription[] = []
  code = ''
  step = PaymentStep.Init
  PaymentStep = PaymentStep

  closeModal() {
    this.selectedSubscription = null
    if (this.code.toLowerCase() !== 'kionmir') {
      this.step = 0
    }
  }

  isSelectedPaymentMethod() {
    return [PaymentStep.Phone, PaymentStep.Card].indexOf(this.step) !== -1
  }

  async mounted() {
    mainService.isFooterVisible.next(false)
    this.code = httpService.promoCode

    if (this.code.toLowerCase() === "kionmir") {
      this.step = PaymentStep.Card
    }
    await this.requestPromo()
  }

  private async requestPromo() {
    try {
      const response = (await httpService.getSubscriptionList()).data
      const promo = response.promoProductPrices
      const [big, small] = joinPromoData(promo)
      this.bigPictures = big
      this.smallPictures = small
      if (this.bigPictures.length === 0 && this.smallPictures.length === 0) {
		gtm.pushEvent({
			event: 'promocode_error',
			event_name: 'promocode_error',
			promocode: httpService.promoCode,
			error_message: response.voucher.status
		})
        httpService.messageError = response.voucher.promoCodeMessage
        this.$router.push({path: '/promo', query: gtm.getAnalyticsQuery()})
      }
      else {
        gtm.pushEvent({
          event: 'promocode_success',
          event_name: 'promocode_success',
          request_id: null,
          promocode: httpService.promoCode,
        })
        this.loading = false
      }

    } catch (err) {
      console.error(err)
    }
  }

  destroyed() {
    mainService.isFooterVisible.next(true)
  }
}
